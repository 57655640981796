var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filingBookCollection,"search":_vm.search,"sort-by":['id'],"sort-desc":"true","loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [100],
        }},on:{"dblclick:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th'),_c('th',[_c('v-select',{attrs:{"items":['Minden év' ].concat( _vm.yearCollection)},model:{value:(_vm.yearFilter),callback:function ($$v) {_vm.yearFilter=$$v},expression:"yearFilter"}})],1),_c('th',[_c('v-select',{attrs:{"items":['Minden státusz' ].concat( _vm.statusCollection)},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1),_c('th')])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('FilingBookModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),_c('v-spacer'),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("flaticon-folder")]),_vm._v("   "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.permissionCan('view'))?_c('router-link',{attrs:{"to":("/settings/filings/filingBooks/" + (item.id))}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.permissionCan('delete') && item.filings.length == 0)?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }